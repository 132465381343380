import {
    APPLICATION_TYPE,
    ClientModuleInitializer,
    LayoutComponentId,
    ModuleConfig
} from "@beesset/upor-client-module-cmn";
import {LogoComponent} from "common";
import React from "react";
import EuropeanFundsLogo from "../common/components/europeanFunds/EuropeanFundsLogo.jpg";
import EuropeanFundsContent from "../common/components/europeanFunds/EuropeanFundsConent";
import SignInLogo from "./components/signInUp/SignInLogo";

function App() {
    const config = React.useMemo(() => {
        let moduleConfig = new ModuleConfig();

        moduleConfig.HttpClientProviderProps = {
            // portalApiDomain: "https://test-cc-grudziadz.beesset.com"
            portalApiDomain: "https://bilety.grudziadz.pl"
        };

        moduleConfig.ConfigProviderProps = {
            LogoComponent: LogoComponent,
            protectedResources: false
        }

        moduleConfig.ThemeProps = {
            primary: "#3b4063",
            secondary: "#d5a665"
        }

        moduleConfig.LayoutProps = {
            componentDefaultProps: {
                [LayoutComponentId.SIGN_IN]: {
                    componentProps: {
                        LogoComponent: SignInLogo
                    }
                },
                [LayoutComponentId.SIGN_UP]: {
                    componentProps: {
                        showPhoneField: true
                    }
                },
                [LayoutComponentId.APPLICATIONS]: {
                    componentProps: {
                        availableTypes: [
                            APPLICATION_TYPE.CITY_CARD_ISSUANCE,
                            APPLICATION_TYPE.OTHER
                        ]
                    }
                }
            },
            EuropeanFundsComponent: {
                Logo: EuropeanFundsLogo,
                content: EuropeanFundsContent
            }
        }
        return moduleConfig;
    }, []);

    return <ClientModuleInitializer {...config}/>;
}

export default App;
