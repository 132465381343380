import {GlobalTranslator, initGlobalTranslator, mergeDeep} from "@beesset/common-utils";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import resources from "../i18n/locate/resources";
import {i18nResources} from "@beesset/upor-client-module-cmn";

initGlobalTranslator(mergeDeep(resources, i18nResources), 'CloudCollector-UPOR-i18nextLng', false);

ReactDOM.render(
    <React.StrictMode>
        <GlobalTranslator>
            <App/>
        </GlobalTranslator>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
