import {useTheme} from "@mui/material";
import {UPORUtils, useConfig} from "@beesset/upor-client-module-cmn";
import Logo from "./Logo.svg";
import LogoContrast from "./Logo_contrast.svg";
import NativeLogo from "./NativeLogo.png";
import NativeLogoContrast from "./NativeLogo_contrast.png";
import NativeLogoEn from "./NativeLogo_en.png";
import NativeLogoEnContrast from "./NativeLogo_en_contrast.png";
import React from "react";
import {useTranslation} from "react-i18next";

function getNativeLogo({language, mode}) {
    switch (mode) {
        case "light": {
            switch (language) {
                case "pl": {
                    return NativeLogo;
                }
                default: {
                    return NativeLogoEn;
                }
            }
        }
        case "dark": {
            switch (language) {
                case "pl": {
                    return NativeLogoContrast;
                }
                default: {
                    return NativeLogoEnContrast;
                }
            }
        }
        default: {
            return NativeLogo;
        }
    }
}

const WebLogoComponent = () => {
    const {t} = useTranslation();
    const theme = useTheme();

    return <img src={theme.palette.mode === "light" ? Logo : LogoContrast} alt={t("components.signIn.logoAltText")}
                style={{
                    maxWidth: 250
                }}/>;
}

const NativeLogoComponent = () => {
    const {t} = useTranslation();

    const theme = useTheme();
    const {getLanguage} = useConfig();

    const language = getLanguage();
    const logo = React.useMemo(() => {
        return getNativeLogo({
            language: language,
            mode: theme.palette.mode
        })
    }, [language, theme.palette.mode]);

    return <img src={logo} alt={t("components.signIn.logoAltText")} style={{
        width: "100%"
    }}/>
}

const SignInLogo = () => {
    return UPORUtils.isNativePlatform()
        ? <NativeLogoComponent/>
        : <WebLogoComponent/>;
}

export default SignInLogo;